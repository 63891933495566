<template>
  <div>
    <h1 class="pb-3">Diversity</h1>
    <div class="flex-gap-15">
      <ValidationProvider v-slot="{ errors }" name="gender" rules="required">
        <Select
          @change="handleChange"
          v-model="currentWorker.gender"
          label="Gender"
          :items="genders"
          item-text="label"
          item-value="id"
          :disabled="readonly('workers.edit.gender')"
          :error-messages="showValidationErrors ? errors : ''"
        />
      </ValidationProvider>
      <Select
        v-model="currentWorker.ethnicGroupId"
        @change="handleChange"
        :items="originOptions"
        label="Ethnic Origin"
        item-text="label"
        item-value="id"
        :disabled="readonly('workers.edit.ethnic-origin')"
        autocomplete
      />
      <ValidationProvider v-slot="{ errors }" rules="required">
        <Select
          :error-messages="showValidationErrors ? errors : ''"
          v-model="currentWorker.countryOfBirth"
          @change="handleChange"
          :items="countries"
          item-text="label"
          item-value="id"
          label="Country of Birth"
          :disabled="readonly('workers.edit.country-of-birth')"
          autocomplete
        />
      </ValidationProvider>
      <Select
        v-model="currentWorker.dda"
        @change="handleChange"
        :items="ddaOptions"
        label="Does the Disability Discrimination Act apply"
        item-text="label"
        item-value="id"
        :disabled="readonly('workers.edit.dda')"
        autocomplete
      />
    </div>
  </div>
</template>

<script>
import Select from "@/components/common/Select";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { GET_COUNTRIES } from "@/store/modules/data/getters";
import { createNamespacedHelpers } from "vuex";
import { ETHNIC_GROUPS, GENDERS, DDA_OPTIONS } from "@/constants/workers";
import { ValidationProvider } from "vee-validate";

const { mapGetters } = createNamespacedHelpers(DATA_NAMESPACE);

export default {
  props: {
    /**
     *  Data object
     */
    currentWorker: {
      type: Object,
      required: true
    },
    permissions: Object,
    isUpdate: Boolean,
    showValidationErrors: Boolean
  },
  components: {
    Select,
    ValidationProvider
  },
  data() {
    return {
      ddaOptions: DDA_OPTIONS,
      originOptions: ETHNIC_GROUPS,
      genders: GENDERS
    };
  },
  created() {
    // Set default DDA option if not already set
    if (!this.currentWorker.dda) {
      this.currentWorker.dda = "unknown";
    }
  },
  computed: {
    ...mapGetters({
      countries: GET_COUNTRIES
    })
  },
  methods: {
    handleChange() {
      this.$emit("onChange", this.currentWorker);
    },
    handleRadioChange(value) {
      this.currentWorker.dda = value;
      this.handleChange();
    },
    isPermitted(permission) {
      return this.permissions[permission];
    },
    readonly(permission) {
      return this.isUpdate && !this.isPermitted(permission);
    }
  }
};
</script>

<style lang="scss" scoped></style>
